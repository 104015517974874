<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showMenu="true" :showBell="true"/>
		<AppTitle title="Friends"/>
    <div class="flex space-x-6 mt-4">
      <router-link :to="{name:'friends-list'}" exact-active-class="text-red-500" class="font-bold text-lg text-gray-300">Friends</router-link>
      <router-link :to="{name:'friends-requests'}" active-class="text-red-500" class="font-bold text-lg text-gray-300">Requests</router-link>
    </div>
		<router-view/>
  </div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
  export default {
    components: {
      TopBar,
      AppTitle,
    },
  }
</script>